.book-detail-wrapper {
    display: flex;
    align-items: center;
}
.book-detail-img {
    width: 300px;
    min-width: 300px;
    margin-right: 48px;
    border-radius: 20px;
}

.book-detail-title {
    font-size: 22px;
    letter-spacing: 0.165px;
    color: #1B2733;
    font-weight: 700;
    margin-bottom: 24px;
}
.book-detail-size {
    font-family: 'Montserrat Regular', sans-serif;
    margin-bottom: 12px;
    color: #1B2733;
    font-size: 14px;
}
.book-detail-author {
    font-size: 16px;
    margin-bottom: 14px;
    opacity: 0.6;
}
.book-detail-category-wrapper {
    margin-bottom: 14px;
}

.book-detail-category-item {
    display: inline-block;
    padding: 6px 12px;
    border-radius: 10px;
    background-color: #ff7a00;
    border: 1px solid #ff7a00;
    transition: all 0.3s;
    color: #fff;
    cursor: pointer;
}
.book-detail-actions {
    margin-top: auto;
    display: flex;

    button {
        border-radius: 10px;
        background-color: #f6f7fb;
        border: 1px solid #f6f7fb;
        padding: 0;
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all 0.3s;
        &:disabled {
            cursor: not-allowed;
            opacity: 0.3;
        }
        svg {
            path {
                transition: all 0.3s;
            }
        }
        &:not(:last-child) {
            margin-right: 16px;
        }
        &:hover {
            background-color: #ff7a00;
            &.btn-cart {
                svg {
                    path {
                        &:nth-child(2) {
                            fill: #fff!important;
                            opacity: 1;
                        }
                        &:nth-child(3) {
                            stroke: #fff!important;
                            opacity: 1;
                        }
                    }
                }
            }
            &.btn-toggle {
                svg {
                    path {
                        &:nth-child(1) {
                            stroke: #fff!important;
                            opacity: 1;
                        }
                        &:nth-child(2) {
                            stroke: #fff!important;
                            opacity: 1;
                        }
                    }
                }
            }
            &.btn-favourite {
                svg {
                    path {
                        &:nth-child(1) {
                            stroke: #fff!important;
                            opacity: 1;
                        }
                        &:nth-child(2) {
                            stroke: #fff!important;
                            opacity: 1;
                        }
                    }
                }
            }
            &.btn-edit {
                svg {
                    path {
                        &:nth-child(1) {
                            stroke: #fff!important;
                            opacity: 1;
                        }
                        &:nth-child(2) {
                            stroke: #fff!important;
                            opacity: 1;
                        }
                        &:nth-child(3) {
                            stroke: #fff!important;
                            opacity: 1;
                        }
                    }
                }
            }
            &.btn-delete {
                svg {
                    path {
                        &:nth-child(1) {
                            stroke: #fff!important;
                            opacity: 1;
                        }
                        &:nth-child(2) {
                            fill: #fff!important;
                            opacity: 1;
                        }
                        &:nth-child(3) {
                            stroke: #fff!important;
                            opacity: 1;
                        }
                    }
                }
            }

        }
        &.selected {
            background-color: #ff7a00;
            &.btn-favourite {
                svg {
                    path {
                        &:nth-child(1) {
                            stroke: #fff!important;
                            opacity: 1;
                        }
                        &:nth-child(2) {
                            stroke: #fff!important;
                            opacity: 1;
                        }
                    }
                }
            }
            &.btn-cart {
                svg {
                    path {
                        &:nth-child(2) {
                            fill: #fff!important;
                            opacity: 1;
                        }
                        &:nth-child(3) {
                            stroke: #fff!important;
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
    .ant-tooltip-disabled-compatible-wrapper {
        margin-right: 16px;
    }
}