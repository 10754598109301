.inner-page-header {
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    box-shadow: 0 2px 8px #f0f1f2;
    padding: 12px 0;
    z-index: 100;
}

.header-logo-wrapper {
    width: 280px;
    margin-right: 32px;
    border-right: 1px solid #DFE2E6;
    min-width: 280px;
}
.header-search-wrapper{
    width: 100%;
}
.header-actions-wrapper {
    margin-left: auto;
    display: flex;
    align-items: center;

    .ant-btn-text {
        display: flex;
        align-items: center;
    }
}

.ant-drawer-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 0;



}
.ant-drawer-body-title {
    margin-top: 0;
    font-size: 28px;
    line-height: 27px;
    margin-bottom: 36px;
    letter-spacing: 0.165px;
    color: #1B2733;
    font-weight: 700;
}
.ant-drawer-body-form {
    min-width: 400px;
}
.btn-active {
    background-color: rgba(255, 122, 0, 0.3);
    border-radius: 10px;
    &:hover {
        background-color: rgba(255, 122, 0, 0.3);
    }
}
