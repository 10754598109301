.book-page-wrapper {
    display: flex;
}
.book-page-content {
    width: 100%;
}
.books-page-filters {
    border-radius: 50px;
    border: 1px solid #DFE2E6;
    padding: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 36px;

    button {
        display: flex;
        align-items: center;
    }
    .books-page-filters__actions {
        display: flex;
        align-items: center;
        margin-left: auto;
    }
    .ant-select {
        &.ant-select-focused {

            .ant-select-selector {
                box-shadow: none!important;
                border-color: #ff7a00!important;
            }
        }
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        height: 40px;
        border-radius: 20px;
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
        height: 40px;
    }
    .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
        line-height: 40px;
    }
    .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
        border-color: #ff7a00;
    }
}