@font-face {
  font-family: 'Montserrat Regular';
  src: url('./assets/fonts/MontserratMedium/MontserratMedium.woff') format('woff'),
  url('./assets/fonts/MontserratMedium/MontserratMedium.woff2') format('woff2'),
  url('./assets/fonts/MontserratMedium/MontserratMedium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat Bold';
  src: url('./assets/fonts/montserratbold.woff') format('woff'),
  url('./assets/fonts/montserratbold.woff2') format('woff2'),
  url('./assets/fonts/montserratbold.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: 'Montserrat Regular', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  outline: none;
}

input:-webkit-autofill{
  box-shadow:inset 0 0 0 1000px #fff!important;
}

input:hover:-webkit-autofill {
  box-shadow:inset 0 0 0 1000px #fff!important;
}

input:focus:-webkit-autofill {
  box-shadow:inset 0 0 0 1000px #fff!important;
}

.ant-form-item {
  margin-bottom: 22px;
}

.ant-form-vertical .ant-form-item-label {
  padding: 0 0 12px;
  label {
    color: #6B6C6F;
    font-family: 'Montserrat Regular', sans-serif;
  }
}

.ant-input {
  display: block;
  height: 44px;
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #DFE2E6;
  box-sizing: border-box;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  padding: 4px 11px 4px 20px;

  &:hover {
    border-color: #ff7a00!important;
  }

  &:focus {
    border-color: #ff7a00!important;
    box-shadow: none;
  }
}

.ant-input-affix-wrapper {
  position: relative;
  width: 100%;
  min-width: 0;
  padding: 4px 11px 4px 20px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #DFE2E6;
  border-radius: 20px;
  transition: all 0.3s;
  display: inline-flex;

  input {
    height: 34px;
    box-shadow: none;
    border-radius: 0;
  }
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #ff7a00;
  border-right-width: 1px !important;
  z-index: 1;
}

.ant-input-affix-wrapper-focused {
  box-shadow: none;
}

.ant-input-affix-wrapper {
  &.custom {
    padding: 4px 11px 4px 11px;
    border-radius: 0;
    border: none;
    .ant-input {
      border-radius: 0;
      box-shadow: none;
    }
  }
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: rgba(255, 122, 0, 0.3);
}
.ant-modal-content {
  border-radius: 20px;
  overflow: hidden;
}
.ant-modal-title {
  margin-top: 0;
  font-size: 28px;
  line-height: 27px;
  margin-bottom: 0;
  letter-spacing: 0.165px;
  color: #1B2733;
  font-weight: 700;
}

.container {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1400px;
  padding: 0 16px;
  margin: 0 auto;
}

.list-wrapper {
  width: 100%;
  h2 {
    margin: 0;
    font-size: 36px;
    letter-spacing: 0.165px;
    color: #1B2733;
    font-weight: 700;
  }
}
.book-page-wrapper {
  width: 100%;
  h2 {
    margin: 0;
    font-size: 36px;

    letter-spacing: 0.165px;
    color: #1B2733;
    font-weight: 700;
  }
}

.table-id {
  width: 60px;
}
.table-avatar {
  width: 80px;
}
.table-fillname {
  width: 200px;
}
.table-email {
  width: 200px;
}
.table-tags {
  width: 210px;
  margin-left: auto;
}
.table-category {
  width: 250px;
}
.table-actions {
  margin-left: auto;
  display: flex;
  min-width: 60px;

  button {
    padding: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;
    width: 24px;
    height: 24px;
    &:not(:last-child) {
      margin-right: 12px;
    }
  }
}

.page-title-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 48px;
  button {
    display: flex;
    align-items: center;
  }
}

.button-wrapper {
  display: flex;
  justify-content: center;
  .ant-btn {
    margin-top: 16px;
    height: 40px;
    background: rgba(255, 255, 255, 0.0001);
    border: 2px solid #ff7a00;
    box-sizing: border-box;
    border-radius: 20px;
    color: #ff7a00;
    font-weight: 700;
    width: 160px;
    &:hover {
      color: #fff;
      background: #ff7a00;
    }
  }
}

.confirm_dialog {
  border-radius: 6px;
  padding: 24px;
  background-color: #10182F;
  color: #fff;
  position: fixed;
  width: 100%;
  max-width: 500px;
  min-height: 100px;
  z-index: 300;
  left: 50%;
  top: 0;
  box-shadow: 0 8px 10px rgba(14, 15, 37, 0.415947);
  transform: translate(-50%, -112%);
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
  h3 {
    font-size: 26px;
    margin-bottom: 0;
    color: #fff;
  }
  .button_wrap {
    margin-left: auto;
    display: flex;
    align-items: center;
    button {
      border: none;
      cursor: pointer;
      background-color: transparent;
      display: block;
      width: 36px;
      height: 36px;
      background-size: cover;
      transition: all 0.3s ease-in-out;
      &:not(:last-child) {
        margin-right: 24px;
      }
      //&.cancel_delete {
      //    background-image: url("../img/cancel.svg");
      //    background-size: cover;
      //}
      //&.confirm_delete {
      //    background-image: url("../img/done.svg");
      //    background-size: cover;
      //}
    }
  }

  &.is_open {
    transform: translate(-50%, 12%);
  }
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #ff7a00;
  border-color: #ff7a00;
}
.ant-form-item-explain, .ant-form-item-extra {
  position: absolute;
  bottom: -24px;
}

.ant-select {
  &.ant-select-focused {

    .ant-select-selector {
      box-shadow: none!important;
      border-color: #ff7a00!important;
    }
  }
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  min-height: 40px;
  border-radius: 20px;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 40px;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
  line-height: 40px;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #ff7a00;
}

.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 40px;
}

.ant-upload.ant-upload-select-picture-card {
  overflow: hidden;
}

.ant-checkbox-input {
  &:focus {
    border: none;
  }
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #ff7a00;
}
.ant-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #ff7a00;
  border-radius: 2px;
  visibility: hidden;
  -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
  animation: antCheckboxEffect 0.36s ease-in-out;
  -webkit-animation-fill-mode: backwards;
  animation-fill-mode: backwards;
  content: '';
}
.tox-tinymce {
  border-radius: 20px!important;
  margin-bottom: 22px;
}
.tox-statusbar {
  display: none!important;
}
.ant-switch-checked {
  background: #ff7a00;
}
.ant-switch-checked:focus {
  box-shadow: none!important;
}
.ant-switch:focus {
  box-shadow: none!important;
}
.ant-input-number:hover {
  border-color: #ff7a00;

}
.ant-input-number-focused {
  border-color: #ff7a00;
  box-shadow: none;
}

.ant-select-multiple .ant-select-selection-item {
  border-radius: 20px;
}

.ant-select-multiple .ant-select-selector {
  padding: 8px 8px;
}

.book-modal-upload {
  display: flex;
  .ant-upload-picture-card-wrapper {
    width: 120px;
  }
}