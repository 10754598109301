.book-card-item {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 15px;
    transition: all 0.3s;
    border: 1px solid transparent;
    border-radius: 20px;
    &:hover {
        border-color: #DFE2E6;
    }
    &__new {
        background-color: #ff7a00;
        color: #fff;
        display: inline-block;
        position: absolute;
        top: 25px;
        right: 25px;
        border-radius: 20px;
        padding: 4px 12px;
        z-index: 60;
    }

    &__img {
        display: block;
        margin-bottom: 20px;
        max-width: 100%;
        border-radius: 20px;
        transition: all 0.3s;
        cursor: pointer;
    }

    &__title {
        margin-bottom: 12px;
        color: #1B2733;
        font-weight: 700;
        font-size: 16px;
    }

    &__author {
        font-size: 16px;
        margin-bottom: 8px;
        opacity: 0.6;
    }
    &__count {
        font-size: 14px;
        background-color: #0ece0e;
        color: #fff;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        overflow: hidden;
        text-align: center;
        line-height: 20px;
        //position: absolute;
        //top: 30px;
        //left: 30px;
        display: inline-block;
        vertical-align: sub;
        margin-right: 4px;
    }
    &__used {
        font-size: 14px;
        margin-bottom: 12px;
        span {
            display: inline-block;
            &:not(:last-child) {
                margin-right: 8px;
                &:after {
                    content: ",";
                }
            }
        }
    }
    &__actions {
        margin-top: auto;
        display: flex;
        justify-content: center;

        button {
            border-radius: 10px;
            background-color: #f6f7fb;
            border: 1px solid #f6f7fb;
            padding: 0;
            width: 36px;
            height: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition: all 0.3s;
            &:disabled {
                cursor: not-allowed;
                opacity: 0.3;
            }
            svg {
                path {
                    transition: all 0.3s;
                }
            }
            &:not(:last-child) {
                margin-right: 16px;
            }
            &:hover {
                background-color: #ff7a00;
                &.btn-cart {
                    svg {
                        path {
                            &:nth-child(2) {
                                fill: #fff!important;
                                opacity: 1;
                            }
                            &:nth-child(3) {
                                stroke: #fff!important;
                                opacity: 1;
                            }
                        }
                    }
                }
                &.btn-toggle {
                    svg {
                        path {
                            &:nth-child(1) {
                                stroke: #fff!important;
                                opacity: 1;
                            }
                            &:nth-child(2) {
                                stroke: #fff!important;
                                opacity: 1;
                            }
                        }
                    }
                }
                &.btn-favourite {
                    svg {
                        path {
                            &:nth-child(1) {
                                stroke: #fff!important;
                                opacity: 1;
                            }
                            &:nth-child(2) {
                                stroke: #fff!important;
                                opacity: 1;
                            }
                        }
                    }
                }
                &.btn-edit {
                    svg {
                        path {
                            &:nth-child(1) {
                                stroke: #fff!important;
                                opacity: 1;
                            }
                            &:nth-child(2) {
                                stroke: #fff!important;
                                opacity: 1;
                            }
                            &:nth-child(3) {
                                stroke: #fff!important;
                                opacity: 1;
                            }
                        }
                    }
                }
                &.btn-delete {
                    svg {
                        path {
                            &:nth-child(1) {
                                stroke: #fff!important;
                                opacity: 1;
                            }
                            &:nth-child(2) {
                                fill: #fff!important;
                                opacity: 1;
                            }
                            &:nth-child(3) {
                                stroke: #fff!important;
                                opacity: 1;
                            }
                        }
                    }
                }

            }
            &.selected {
                background-color: #ff7a00;
                &.btn-favourite {
                    svg {
                        path {
                            &:nth-child(1) {
                                stroke: #fff!important;
                                opacity: 1;
                            }
                            &:nth-child(2) {
                                stroke: #fff!important;
                                opacity: 1;
                            }
                        }
                    }
                }
                &.btn-cart {
                    svg {
                        path {
                            &:nth-child(2) {
                                fill: #fff!important;
                                opacity: 1;
                            }
                            &:nth-child(3) {
                                stroke: #fff!important;
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
        .ant-tooltip-disabled-compatible-wrapper {
            margin-right: 16px;
        }
    }
    &:hover {
        .book-card-item__img {
            transform: scale(1.12);
        }
    }
}

@media (min-width: 768px) {
    .book-card-item {
        width: 50%;
    }
}

@media (min-width: 992px) {
    .book-card-item {
        width: 33.33%;
    }
}

@media (min-width: 1200px) {
    .book-card-item {
        width: 25%;
    }
}