.book-page-sidebar {
    min-width: 280px;
    width: 280px;
    margin-right: 32px;
    border-right: 1px solid #DFE2E6;
    padding-right: 24px;
    padding-bottom: 24px;
    margin-bottom: 24px;
}

.book-page-sidebar__header {
    margin-top: 0;
    font-size: 24px;
    line-height: 27px;
    letter-spacing: 0.165px;
    color: #1B2733;
    font-weight: 700;
    margin-bottom: 16px;
}

.book-page-sidebar__list {
    display: flex;
    flex-direction: column;
    &:not(:last-child) {
        margin-bottom: 36px;
    }
}

.book-page-sidebar__item {
    cursor: pointer;
    text-align: left;
    padding: 6px 12px;
    border-radius: 10px;
    background-color: #f6f7fb;
    border: 1px solid #f6f7fb;
    transition: all 0.3s;
    &:not(:last-child) {
        margin-bottom: 8px;
    }
    &:hover {
        border-color: #ff7a00;
    }
    &.active {
        border-color: #ff7a00;
        color: #fff;
        background-color: #ff7a00;
    }
}
