
.login-layout {
    display: flex;
}

.login-form-wrapper {
    width: 40%;
    background: #f6f7fb;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

}

.login-form-img {
    width: 60%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

.login-form-content {
    min-width: 400px;
    .ant-btn {
        margin-top: 16px;
        height: 40px;
        background: rgba(255, 255, 255, 0.0001);
        border: 2px solid #ff7a00;
        box-sizing: border-box;
        border-radius: 20px;
        color: #ff7a00;
        width: 160px;
        &:hover {
            color: #fff;
            background: #ff7a00;
            border: 2px solid #ff7a00;
        }
        &:focus {
            color: #fff;
            background: #ff7a00;
            border: 2px solid #ff7a00;
        }
    }
}

.login-form-title {
    margin-top: 0;
    font-size: 28px;
    line-height: 27px;
    margin-bottom: 36px;
    letter-spacing: 0.165px;
    color: #1B2733;
    font-weight: 700;
}